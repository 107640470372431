import revive_payload_client_MzUzZ71BKV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.0_db0@0.2.1_drizzle-orm@0.33.0_rollup@4.29.1_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_MnjZ2kD134 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.0_db0@0.2.1_drizzle-orm@0.33.0_rollup@4.29.1_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VRCNFDewX3 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.0_db0@0.2.1_drizzle-orm@0.33.0_rollup@4.29.1_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T3JIlzn6P3 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.0_db0@0.2.1_drizzle-orm@0.33.0_rollup@4.29.1_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_aSaGHvWcB7 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.0_db0@0.2.1_drizzle-orm@0.33.0_rollup@4.29.1_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_nN0ayN3zZX from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.0_db0@0.2.1_drizzle-orm@0.33.0_rollup@4.29.1_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_KurgSKMYvt from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.0_db0@0.2.1_drizzle-orm@0.33.0_rollup@4.29.1_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ww1zCca2Gg from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.29.1_typescript@5.7.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_mg08zEFHHZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.0_db0@0.2.1_drizzle-orm@0.33.0_rollup@4.29.1_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_xgrUMUajaP from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@8.47.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.30.0_@opentelemetry+instru_nlzck65e4m4vydpbpipuyhjcl4/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_client_O11SDobVVi from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-fathom@0.0.2_rollup@4.29.1/node_modules/nuxt-fathom/dist/runtime/plugin.client.mjs";
import session_client_kYWtCgdwEu from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-auth-utils@0.3.9_rollup@4.29.1/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import plugin_Mr7DuCEPMx from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.10.3_rollup@4.29.1_vite@6.0.6_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_6Ph1iQzNHk from "/opt/buildhome/repo/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/opt/buildhome/repo/.nuxt/formkitPlugin.mjs";
import floatingVue_JzRtS4Vfwe from "/opt/buildhome/repo/plugins/floatingVue.ts";
import formkit_DahpcjAQo5 from "/opt/buildhome/repo/plugins/formkit.ts";
export default [
  revive_payload_client_MzUzZ71BKV,
  unhead_MnjZ2kD134,
  router_VRCNFDewX3,
  payload_client_T3JIlzn6P3,
  navigation_repaint_client_aSaGHvWcB7,
  check_outdated_build_client_nN0ayN3zZX,
  chunk_reload_client_KurgSKMYvt,
  plugin_vue3_ww1zCca2Gg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mg08zEFHHZ,
  sentry_client_xgrUMUajaP,
  sentry_client_config_o34nk2sJbg,
  plugin_client_O11SDobVVi,
  session_client_kYWtCgdwEu,
  plugin_Mr7DuCEPMx,
  plugin_6Ph1iQzNHk,
  formkitPlugin_pZqjah0RUG,
  floatingVue_JzRtS4Vfwe,
  formkit_DahpcjAQo5
]